import { theme } from "@chakra-ui/core"

export default {
  ...theme,
  colors: {
    ...theme.colors,
    background: "#343434",
    accent: "#FD1897",
    gray: {
      ...theme.colors.gray,
      100: "#F9F9F9",
    },
    yellow: "#FFFE37",
  },
  fonts: {
    ...theme.fonts,
    heading: "Maven Pro, system-ui, sans-serif",
    body: "Maven Pro, system-ui, sans-serif",
  },
  fontWeights: {
    normal: 400,
    medium: 500,
    bold: 700,
    black: 900,
  },
}
