// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-sanity-templates-frontpage-jsx": () => import("./../../../src/sanity/templates/frontpage.jsx" /* webpackChunkName: "component---src-sanity-templates-frontpage-jsx" */),
  "component---src-sanity-templates-page-jsx": () => import("./../../../src/sanity/templates/page.jsx" /* webpackChunkName: "component---src-sanity-templates-page-jsx" */),
  "component---src-sanity-templates-post-jsx": () => import("./../../../src/sanity/templates/post.jsx" /* webpackChunkName: "component---src-sanity-templates-post-jsx" */)
}

